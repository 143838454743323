<template>
    <b-tabs pills card h-100 d-flex flex-column style="width: 100%" :class="{ disabled: loadingConnectionCheck }">
        <!-- Connection configuration -->
        <b-tab class="pb-0">
            <template #title>
                <span class="tabHeaderText">
                    {{ $t('Connection') }}
                    <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="invalidState" />
                </span>
            </template>
            <div>
                <b-row>
                    <b-col cols="6">
                        <b-row>
                            <b-col cols="7">
                                <b-form-group :label="$t('equipment.Patlite.address') + ' *'" label-for="address">
                                    <b-form-input id="address" v-model="address" :state="addressState" @change="(value) => {
                                            if (value == null || value == '') {
                                                address = 'localhost';
                                            }
                                            isConnected = false;
                                            $refs.dataGrid.instance.deselectAll();
                                            columnsFound = [];
                                        }
                                        " :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="5">
                                <b-form-group label-for="checkConnectionButton">
                                    <template #label>
                                        <div class="blank-label">&nbsp;</div>
                                    </template>
                                    <b-button @click="onCheckConnection" id="checkConnectionButton" class="btn-block"
                                        :class="{ disabled: !$store.state.auth.user.permissions.PermissionRead || loadingConnectionCheck }">
                                        {{ $t('equipment.Patlite.checkConnection') }}
                                        <font-awesome-icon v-if="loadingConnectionCheck" icon="fas fa-spinner-third"
                                            class="fa-lg fa-spin-custom" />
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="7">
                                <b-form-group :label="$t('equipment.Patlite.discoverTime') + ' *'" label-for="discover">
                                    <b-form-input id="discover" v-model="discover" type="number" min="10" max="1800"
                                        @change="(value) => {
                                                if (value == null || value == '') {
                                                    discover = 300;
                                                } else if (value > 1800) {
                                                    discover = 1800;
                                                } else if (value <= 0) {
                                                    discover = 10;
                                                }
                                            }
                                            " :state="discoverTime"
                                        :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite || !isConnected }" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="5">
                                <b-form-group label-for="discoveryButton">
                                    <template #label>
                                        <div class="blank-label">&nbsp;</div>
                                    </template>
                                    <b-button @click="onDiscovery" id="discoveryButton" class="btn-block"
                                        :class="{ disabled: !$store.state.auth.user.permissions.PermissionRead || loadingDiscovery || !isConnected }">
                                        {{ $t('equipment.Patlite.discovery') }}
                                        <font-awesome-icon v-if="loadingDiscovery" icon="fas fa-spinner-third"
                                            class="fa-lg fa-spin-custom" />
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('equipment.Patlite.columns') + ' (' + columnsCount +')'" label-for="columnsFound">
                            <DxDataGrid id="columnsFound" keyExpr="Address" ref="dataGrid" :dataSource="columnsFound"
                                :row-alternation-enabled="true" :paging="{ enabled: false }"
                                :editing="{ mode: 'cell', allowUpdating: true }" @content-ready="onContentReady"
                                @row-updating="checkDuplicateNames" :style="gridStyle">
                                <DxSelection :select-all-mode="'page'" :show-check-boxes-mode="'onClick'" mode="multiple" />
                                <DxColumn dataField="Name" :caption="$t('Name')" :allow-editing="true"
                                    :validation-rules="[{ type: 'required' }]" />
                                <DxColumn dataField="Address" :caption="$t('Address')" :allow-editing="false" />
                            </DxDataGrid>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
        </b-tab>
    </b-tabs>
</template>

<script>
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ItemConfiguration from '@/components/configuration/Equipments/ItemConfiguration.vue';
import { DxDataGrid, DxColumn, DxSorting, DxSelection } from 'devextreme-vue/data-grid';
import ToastAlert from '@/utils/ToastAlert';
import { on } from 'devextreme/events';

export default {
    name: 'ConfigPatlite',
    props: {
        equipment: Object,
        onValidate: Function,
        updateMode: Boolean,
    },
    components: {
        ItemConfiguration,
        DxDataGrid,
        DxSelection,
        DxColumn,
        DxSorting,
    },
    async mounted() { },
    data() {
        return {
            firstLoad: true,
            isConnected: false,
            loadingConnectionCheck: false,
            loadingDiscovery: false,
            address: this.equipment?.Address ?? 'localhost',
            discover: this.equipment?.discover ?? 300,
            originalDiscover: this.equipment?.discover ?? 300,
            discoverTimeIntervalId: null,
            columnsFound: this.equipment?.Columns ?? [],
        };
    },
    computed: {
        invalidState() {
            return this.addressState === false || this.discoverTime === false;
        },
        discoverTime() {
            return this.discover == null || this.discover < 0 ? false : null;
        },
        addressState() {
            return this.address == null || this.address == '' ? false : null;
        },
        gridStyle() {
            return 'min-height: 300px; height: calc(100vh - 550px); max-height: calc(100vh - 550px); min-width: 100%; max-width: 100%;';
        },
        columnsCount() {
            return this.columnsFound.length;
        },
    },
    methods: {
        async onContentReady(e) {
            if (this.firstLoad && this.columnsFound != null && this.columnsFound.length > 0) {
                const selectedRows = this.columnsFound.filter((x) => x.IsSelected);
                selectedRows.forEach((row) => {
                    e.component.selectRows([row.Address], true);
                });
            }
        },
        async checkDuplicateNames(e) {
            const rows = e.component.getVisibleRows();
            const duplicate = rows.filter((x) => x.data.Name === e.newData.Name && x.data.Address !== e.key);
            if (duplicate.length > 0) {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.Patlite.duplicateName'), 'warning'));
                e.cancel = true;
            }
        },
        decrementDiscoverEachSecond() {
            this.originalDiscover = this.discover;
            if (this.discoverTimeIntervalId != null) {
                clearInterval(this.discoverTimeIntervalId);
            }
            this.discoverTimeIntervalId = setInterval(() => {
                if (this.discover > 0) {
                    this.discover--;
                }
            }, 1000);
        },
        async saveEquipmentCommunication() { },
        getPatiliteColumns() {
            return this.$refs.dataGrid.instance.getVisibleRows().map((x) => {
                return {
                    Name: x.data.Name,
                    Address: x.data.Address,
                    IsSelected: x.isSelected,
                };
            });
        },
        /**
         * Synchronizes the data changes from the wizard into the given equipment. This method should be called before updating / saving / adding
         * the equipment in the parent control.
         * @public This method is public.
         */
        async synchronizeEquipment() {
            this.equipment.address = this.address;
            this.equipment.discover = this.originalDiscover;
            this.equipment.Columns = this.getPatiliteColumns();
        },
        /**
         * Checks if the given connection parameters result in a valid connection.
         */
        async onCheckConnection(e) {
            this.loadingConnectionCheck = true;
            this.isConnected = false;
            const result = await ProdComEquipmentService.PatliteCheckConnection(this.validate());
            if (result.ret.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret.ConnectionFailedMessage), 'warning'));
            } else {
                if (!result.ret.Connected) {
                    if (result.ret.ConnectionFailedMessage !== '') {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret.ConnectionFailedMessage), 'warning'));
                    }
                } else {
                    this.isConnected = true;
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.Patlite.Found'), 'success', this.$t('ConnectionSuccess')));
                }
            }
            this.loadingConnectionCheck = false;
        },
        async onDiscovery(e) {
            this.loadingDiscovery = true;
            this.decrementDiscoverEachSecond();
            const result = await ProdComEquipmentService.PatliteDiscovery(this.validate());
            if (result.ret.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret.ConnectionFailedMessage), 'warning'));
            } else {
                this.$EventBus.$emit('show-toast', new ToastAlert(object.keys(result.ret).length + ' ' + this.$t('equipment.Patlite.columns'), 'success', this.$t('ConnectionSuccess')));
            }
            this.columnsFound = result.ret;
            this.loadingDiscovery = false;
            clearInterval(this.discoverTimeIntervalId);
            this.discover = this.originalDiscover;
        },
        /**
         * Returns the correctly formated object of connection parameters
         */
        validate() {
            if (this.invalidState) return null;
            return {
                Address: this.address,
                Name: this.equipment.Name,
                Description: this.description,
                DiscoverTime: this.originalDiscover,
                Columns: this.getPatiliteColumns(),
            };
        },
    },
};
</script>