<template>
    <b-tabs pills card h-100 d-flex flex-column style="width: 100%">
        <!-- Connection configuration -->
        <b-tab>
            <template #title>
                <span class="tabHeaderText">
                    {{ $t('Connection') }}
                </span>
            </template>
            <b-form>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('opcConnexionType') + ' *'" label-for="connexionType">
                            <b-form-radio-group id="connexionType" v-model="connexionType" :options="connexionTypeOptions" name="connexionType" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <hr color="grey" />
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('equipment.modbus.apiAddress')" label-for="apiAddress">
                            <b-form-input id="apiAddress" type="number" min="1" max="100" @change="(value) => {
                                if (value == null || value == '') {
                                    this.apiAddress = 1;
                                } else if (value > 100) {
                                    this.apiAddress = 100;
                                } else if (value < 1) {
                                    this.apiAddress = 1;
                                }
                            }
                                " v-model="apiAddress" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('equipment.modbus.pollingPeriod')" label-for="pollingPeriod">
                            <b-form-input id="pollingPeriod" type="number" min="100" max="3600000" @change="(value) => {
                                if (value == null || value == '') {
                                    this.pollingPeriod = 500;
                                } else if (value > 3600000) {
                                    this.pollingPeriod = 3600000;
                                } else if (value < 100) {
                                    this.pollingPeriod = 100;
                                }
                            }
                                " v-model="pollingPeriod" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('equipment.modbus.retriesBeforeFail')" label-for="retryBeforeFail">
                            <b-form-input id="retryBeforeFail" type="number" min="0" max="10" @change="(value) => {
                                if (value == null || value == '') {
                                    this.retryBeforeFail = 2;
                                } else if (value > 10) {
                                    this.retryBeforeFail = 10;
                                } else if (value < 0) {
                                    this.retryBeforeFail = 0;
                                }
                            }
                                " v-model="retryBeforeFail" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('equipment.modbus.frameShift')" label-for="frameShift">
                            <b-form-input id="frameShift" type="number" min="0" max="1024" @change="(value) => {
                                if (value == null || value == '') {
                                    this.frameShift = 2;
                                } else if (value > 1024) {
                                    this.frameShift = 1024;
                                } else if (value < 0) {
                                    this.frameShift = 0;
                                }
                            }
                                " v-model="frameShift" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-tab>
        <!-- Modbus query configuration -->
        <b-tab>
            <template #title>
                <span class="tabHeaderText">{{ $t('equipment.modbus.queryConfiguration') }} </span>
            </template>
            <b-row>
                <b-col>
                    <b-form-group label="Discrete Inputs" label-for="discreteInputs">
                        <b-form-input id="discreteInputs" type="number" min="1" max="2000" @change="(value) => {
                            if (value == null || value == '') {
                                this.discreteInputs = 2000;
                            } else if (value > 2000) {
                                this.discreteInputs = 2000;
                            } else if (value < 1) {
                                this.discreteInputs = 1;
                            }
                        }
                            " v-model="discreteInputs" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Input Registers" label-for="inputRegisters">
                        <b-form-input id="inputRegisters" type="number" min="1" max="125" @change="(value) => {
                            if (value == null || value == '') {
                                this.inputRegisters = 125;
                            } else if (value > 125) {
                                this.inputRegisters = 125;
                            } else if (value < 1) {
                                this.inputRegisters = 1;
                            }
                        }
                            " v-model="inputRegisters" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Coils" label-for="coils">
                        <b-form-input id="coils" type="number" min="1" max="2000" @change="(value) => {
                            if (value == null || value == '') {
                                this.coils = 2000;
                            } else if (value > 2000) {
                                this.coils = 2000;
                            } else if (value < 1) {
                                this.coils = 1;
                            }
                        }
                            " v-model="coils" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Holding Registers" label-for="holdingRegisters">
                        <b-form-input id="holdingRegisters" type="number" min="1" max="125" @change="(value) => {
                            if (value == null || value == '') {
                                this.holdingRegisters = 125;
                            } else if (value > 125) {
                                this.holdingRegisters = 125;
                            } else if (value < 1) {
                                this.holdingRegisters = 1;
                            }
                        }
                            " v-model="holdingRegisters" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-tab>
        <!-- TCP configuration -->
        <b-tab v-if="connexionType == 0">
            <template #title>
                <span class="tabHeaderText">
                    {{ $t('equipment.modbus.tcpConfiguration') }}
                    <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="invalidTCPIPState" />
                </span>
            </template>
            <b-row>
                <b-col>
                    <b-form-group :label="`${$t('IP')} *`" label-for="ipAddress">
                        <b-form-input id="ipAddress" :state="ipAddressState" v-model="ipAddress" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('Timeout') + ' (ms) *'" label-for="timeout">
                        <b-form-input id="timeout" type="number" min="0" max="120000" @change="(value) => {
                            if (value == null || value == '') {
                                this.timeout = 2000;
                            } else if (value > 120000) {
                                this.timeout = 120000;
                            } else if (value < 0) {
                                this.timeout = 0;
                            }
                        }
                            " v-model="timeout" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group :label="$t('equipment.modbus.port')" label-for="port">
                        <b-form-input id="port" type="number" min="1" max="65535" @change="(value) => {
                            if (value == null || value == '') {
                                this.port = 502;
                            } else if (value > 65535) {
                                this.port = 65535;
                            } else if (value < 1) {
                                this.port = 1;
                            }
                        }
                            " v-model="port" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('equipment.modbus.silenceInterval') + ' (ms)'" label-for="silenceInterval">
                        <b-form-input id="silenceInterval" min="0" max="120000" @change="(value) => {
                            if (value == null || value == '') {
                                this.silenceInterval = 500;
                            } else if (value > 120000) {
                                this.silenceInterval = 120000;
                            } else if (value < 0) {
                                this.silenceInterval = 0;
                            }
                        }
                            " type="number" v-model="silenceInterval" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-checkbox v-model="serialViaTCP" :value="true" :unchecked-value="false" switch style="margin-top: 40px" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }">
                        {{ $t('equipment.modbus.serialOverTcp') }}
                    </b-form-checkbox>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('equipment.modbus.transmission')" label-for="transmission">
                        <b-form-select id="transmission" v-model="transmission" :disabled="true" :options="availableTransmissions" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-tab>
        <!-- Serial configuration -->
        <b-tab v-if="connexionType == 1">
            <template #title>
                <span class="tabHeaderText">{{ $t('equipment.modbus.comPort') }}</span>
            </template>
            <b-row :disabled="availablePorts == null || availablePorts.length == 0">
                <b-col>
                    <b-form-group :label="$t('equipment.modbus.comPortUsed')" label-for="comPort">
                        <b-form-select id="comPort" v-model="comPort" :options="availablePorts" :disabled="availablePorts == null || availablePorts.length == 0" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-button variant="primary" style="margin-top: 31px" :disabled="availablePorts == null || availablePorts.length == 0" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }">{{ $t('equipment.modbus.testPort') }}</b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group :label="$t('equipment.modbus.speed') + ' (bit/s)'" label-for="comSpeed">
                        <b-form-select id="comSpeed" v-model="comSpeed" :options="availableSpeeds" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('Timeout') + ' (ms)'" label-for="timeout">
                        <b-form-input id="timeout" type="number" min="1" max="120000" @change="(value) => {
                            if (value == null || value == '') {
                                this.timeout = 2000;
                            } else if (value > 120000) {
                                this.timeout = 120000;
                            } else if (value < 1) {
                                this.timeout = 1;
                            }
                        }
                            " v-model="timeout" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('equipment.modbus.dataBits')" label-for="comDataBits">
                        <b-form-select id="comDataBits" v-model="comDataBits" :options="availableDataBits" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group :label="$t('equipment.modbus.silenceInterval') + ' (ms)'" label-for="silenceInterval">
                        <b-form-input id="silenceInterval" type="number" min="100" max="120000" @change="(value) => {
                            if (value == null || value == '') {
                                this.silenceInterval = 500;
                            } else if (value > 120000) {
                                this.silenceInterval = 120000;
                            } else if (value < 100) {
                                this.silenceInterval = 100;
                            }
                        }
                            " v-model="silenceInterval" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('equipment.modbus.parity')" label-for="comParity">
                        <b-form-select id="comParity" v-model="comParity" :options="availableParities" :class="{ disabled: readonly }" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('equipment.modbus.transmission')" label-for="transmission">
                        <b-form-select id="transmission" v-model="transmission" :options="availableTransmissions" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row> </b-row>
            <b-row>
                <b-col cols="4">
                    <b-form-group :label="$t('equipment.modbus.stopBits')" label-for="comStopBit">
                        <b-form-select id="comStopBit" v-model="comStopBit" :options="availableStopBits" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-tab>
        <!-- Item configuration -->
        <b-tab>
            <template #title>
                {{ $t('Variables') }}
                <b-badge pill variant="info">{{
                    groups
                        .map((gr) => gr.Items.length)
                        .reduce(function (a, b) {
                            return a + b;
                        }, 0)
                }}</b-badge>
            </template>
            <ItemConfiguration ref="itemConfiguration" :columns="itemColumns" :groups="groups" :readonly="!this.$store.state.auth.user.permissions.PermissionWrite" :separateGroupConfig="false" :groupConfiguration="groupConfiguration" :updateMode="updateMode" :RefreshVarCounter="refreshVariablesCounter" :onEditingStartProp="onEditingStart" :onContentReadyProp="onContentReady" :calculateCellDisplayValueProp="calculateCellDisplayValue" />
        </b-tab>
    </b-tabs>
</template>

<script>
import ItemConfiguration from '@/components/configuration/Equipments/ItemConfiguration.vue';
import { uuid } from 'vue-uuid';

export default {
    name: 'ConfigModbus',
    components: {
        ItemConfiguration,
    },
    props: {
        equipment: Object,
        onValidate: Function,
        updateMode: Boolean,
    },
    computed: {
        invalidState() {
            return this.ipAddressState === false;
        },
        invalidTCPIPState() {
            return this.ipAddressState === false;
        },
        ipAddressState() {
            return this.connexionType == 0 && this.ipAddress == '' ? false : null;
        },
    },
    data() {
        const self = this;
        return {
            connexionTypeOptions: [
                { value: 0, text: 'TCP/IP' },
                { value: 1, text: 'Serie' },
            ],
            connexionType: this.equipment?.ConnectionType ?? 0,
            apiAddress: this.equipment?.ApiAddress ?? 1,
            pollingPeriod: this.equipment?.TxRefresh ?? 500,
            retryBeforeFail: this.equipment?.Retries ?? 2,
            frameShift: this.equipment?.FrameShift ?? 4,
            discreteInputs: this.equipment?.DiscreteInput ?? 2000,
            inputRegisters: this.equipment?.InputRegisters ?? 125,
            coils: this.equipment?.Coils ?? 2000,
            holdingRegisters: this.equipment?.HoldingRegisters ?? 125,
            ipAddress: this.equipment?.Host ?? '127.0.0.1',
            timeout: this.equipment?.Timeout ?? 2000,
            port: this.equipment?.TcpPort ?? 502,
            silenceInterval: this.equipment?.SilenceInterval ?? 500,
            serialViaTCP: this.equipment?.SerialOverTCP ?? false,
            availableTransmissions: [
                { text: 'ASCII', value: 0 },
                { text: 'RTU', value: 1 },
            ],
            transmission: this.equipment?.TransmissionMode ?? 0,
            availablePorts: [],
            comPort: this.equipment?.ComPort ?? 0,
            availableSpeeds: [75, 110, 134, 150, 300, 600, 1200, 1800, 2400, 4800, 7200, 9600, 14400, 19200, 38400, 57600, 115200, 128000],
            comSpeed: this.equipment?.BaudRate ?? 9600,
            availableDataBits: [4, 5, 6, 7, 8],
            comDataBits: this.equipment?.ByteSize ?? 8,
            availableParities: [
                { value: 0, text: this.$t('equipment.modbus.parity.none') },
                { value: 2, text: this.$t('equipment.modbus.parity.even') },
                { value: 1, text: this.$t('equipment.modbus.parity.odd') },
                { value: 3, text: this.$t('equipment.modbus.parity.mark') },
                { value: 4, text: this.$t('equipment.modbus.parity.space') },
            ],
            comParity: this.equipment?.Parity ?? 0,
            availableStopBits: [
                { text: '1', value: 1 },
                { text: '1.5', value: 3 },
                { text: '2', value: 2 },
            ],
            comStopBit: this.equipment?.StopBits ?? 1,
            varGridView: null,
            onContentReady: function (e) {
                self.varGridView = e.component;
            },
            calculateCellDisplayValue: function (rowData, dataField) {
                let value = rowData[dataField];
                // if (dataField == 'ValueLength' && (rowData.Type != 'String' && rowData.Type != 'Real')) {
                //     value = null;
                // }
                if (dataField == 'Bit' && (rowData.Type != 'Bool' && rowData.Type != 'Bit')) {
                    value = null;
                }
                return value;
            },
            onEditingStart: function (e) {
                // Manage Type column options
                if (e.data.ZoneName == 'Discretes_Input' || e.data.ZoneName == 'Coils') {
                    e.component.columnOption('Type', 'lookup.dataSource', ['Bool', 'Bit']);
                    if (['Bool', 'Bit'].indexOf(e.data.Type) == -1) {
                        e.data.Type = null;
                    }
                } else {
                    e.component.columnOption('Type', 'lookup.dataSource', ['Bool', 'Bit', 'String', 'Char', 'Real', 'DReal', 'Word', 'DWord', 'Int', 'DInt']);
                }
                // Manage ValueLength column options
                if (e.data.Type == 'String') {
                    e.component.columnOption('ValueLength', 'allowEditing', true);
                    e.data.ValueLength = null;
                } else {
                    e.component.columnOption('ValueLength', 'allowEditing', false);
                    switch (e.data.Type) {
                        case 'Bool':
                        case 'Bit':
                        case 'Word':
                        case 'Int':
                        case 'Char':
                            e.data.ValueLength = 1;
                            break;
                        case 'DWord':
                        case 'DInt':
                        case 'Real':
                            e.data.ValueLength = 2;
                            break;
                        case 'DReal':
                            e.data.ValueLength = 4;
                            break;
                        default:
                            e.data.ValueLength = null;
                            break;
                    }
                }
                // Manage Bit column options
                if (e.data.Type == 'Bool' || e.data.Type == 'Bit') {
                    e.component.columnOption('Bit', 'allowEditing', true);
                    if (e.data.ValueIndex == null || e.data.ValueIndex == '') {
                        e.data.ValueIndex = 0;
                    }
                } else {
                    e.component.columnOption('Bit', 'allowEditing', false);
                    e.data.ValueIndex = 0;
                }
                // Manage BooleanDirection column options
                if (e.data.Type == 'String' || e.data.Type == 'Real' || e.data.Type == 'DReal' || e.data.Type == 'DWord' || e.data.Type == 'DInt') {
                    e.component.columnOption('BooleanDirection', 'allowEditing', true);
                } else {
                    e.component.columnOption('BooleanDirection', 'allowEditing', false);
                    e.data.BooleanDirection = false;
                }
            },
            itemColumns: [
                { key: 'Name', dataField: 'Name', dataType: 'string', caption: this.$t('Name'), required: true, width: 150 },
                {
                    key: 'Zone',
                    dataField: 'ZoneName',
                    dataType: 'string',
                    caption: this.$t('Zone'),
                    required: true,
                    options: ['Discretes_Input', 'Coils', 'Input_Registers', 'Holding_Registers'],
                    width: 150,
                    setCellValue: function (newData, value, currentRowData) {
                        newData.ZoneName = value;
                        switch (value) {
                            case 'Discretes_Input':
                            case 'Coils':
                                self.varGridView.columnOption('Type', 'lookup.dataSource', ['Bool', 'Bit']);
                                if (['Bool', 'Bit'].indexOf(newData.Type) == -1) {
                                    newData.Type = null;
                                }
                                break;
                            default:
                                self.varGridView.columnOption('Type', 'lookup.dataSource', ['Bool', 'Bit', 'String', 'Char', 'Real', 'DReal', 'Word', 'DWord', 'Int', 'DInt']);
                                break;
                        }
                    },
                },
                {
                    key: 'Type',
                    dataField: 'Type',
                    dataType: 'string',
                    caption: this.$t('Type'),
                    required: true,
                    options: ['Bool', 'Bit', 'String', 'Char', 'Real', 'DReal', 'Word', 'DWord', 'Int', 'DInt'],
                    width: 120,
                    setCellValue: function (newData, value, currentRowData) {
                        console.log('Type', value);
                        newData.Type = value;
                        // Manage ValueLength column options
                        if (value == 'String') {
                            self.varGridView.columnOption('ValueLength', 'allowEditing', true);
                            newData.ValueLength = null;
                        } else {
                            self.varGridView.columnOption('ValueLength', 'allowEditing', false);
                            switch (value) {
                                case 'Bool':
                                case 'Bit':
                                case 'Word':
                                case 'Int':
                                case 'Char':
                                    newData.ValueLength = 1;
                                    break;
                                case 'DWord':
                                case 'DInt':
                                case 'Real':
                                    newData.ValueLength = 2;
                                    break;
                                case 'DReal':
                                    newData.ValueLength = 4;
                                    break;
                                default:
                                    newData.ValueLength = null;
                                    break;
                            }
                        }
                        // Manage Bit column options
                        if (value == 'Bool' || value == 'Bit') {
                            self.varGridView.columnOption('Bit', 'allowEditing', true);
                            if (newData.ValueIndex == null || newData.ValueIndex == '') {
                                newData.ValueIndex = 0;
                            }
                        } else {
                            self.varGridView.columnOption('Bit', 'allowEditing', false);
                            newData.ValueIndex = 0;
                        }
                        // Manage BooleanDirection column options
                        if (value == 'String' || value == 'Real' || value == 'DReal' || value == 'DWord' || value == 'DInt') {
                            self.varGridView.columnOption('BooleanDirection', 'allowEditing', true);
                        } else {
                            self.varGridView.columnOption('BooleanDirection', 'allowEditing', false);
                            newData.BooleanDirection = false;
                        }
                    },
                },
                { key: 'Address', dataField: 'Address', dataType: 'string', caption: this.$t('Address'), required: true, width: 120 },
                { key: 'Bit', dataField: 'ValueIndex', dataType: 'number', caption: this.$t('Bit'), required: true, width: 60 },
                { key: 'BooleanDirection', dataField: 'BooleanDirection', dataType: 'boolean', caption: this.$t('Reverse'), required: false, width: 120, alignment: 'center' },
                {
                    key: 'ValueLength', dataField: 'ValueLength', dataType: 'number', caption: this.$t('Size'), required: true, width: 100,
                    editorOptions: {
                        min: 1,
                        max: 40,
                    },
                }
            ],
            groups: this.equipment?.Groups ?? [{ id: uuid.v4(), Name: `${this.$t('Group')} 1`, Active: true, Items: [] }],
            groupConfiguration: { rows: [] },
        };
    },
    methods: {
        validate() {
            return this.invalidState ? null : true;
        },
        async saveEquipmentCommunication() {
            await this.$refs.itemConfiguration.saveEquipmentCommunication();
        },
        async refreshVariablesCounter(searchingForItems, variableCounter) { },
        /**
         * Synchronizes the data changes from the wizard into the given equipment. This method should be called before updateing / saving / adding
         * the equipment in the parent control.
         * @public This method is public.
         */
        async synchronizeEquipment() {
            this.equipment.Groups = this.groups;
            this.equipment.ApiAddress = this.apiAddress;
            this.equipment.TxRefresh = this.pollingPeriod;
            this.equipment.Timeout = this.timeout;
            this.equipment.Host = this.ipAddress;
            this.equipment.ConnectionType = this.connexionType;
            this.equipment.TcpPort = this.port;
            this.equipment.ComPort = this.comPort;
            this.equipment.BaudRate = this.comSpeed;
            this.equipment.ByteSize = this.comDataBits;
            this.equipment.StopBits = this.comStopBit;
            this.equipment.Parity = this.comParity;
            this.equipment.TransmissionMode = this.transmission;
            this.equipment.FrameShift = this.frameShift;
            this.equipment.HoldingRegisters = this.holdingRegisters;
            this.equipment.InputRegisters = this.inputRegisters;
            this.equipment.Coils = this.coils;
            this.equipment.DiscreteInput = this.discreteInputs;
            this.equipment.Retries = this.retryBeforeFail;
            this.equipment.SilenceInterval = this.silenceInterval;
            this.equipment.SerialOverTCP = this.serialViaTCP;
        },
    },
};
</script>