<template>
    <b-tab>
        <template #title>
            {{ $t('actions.consignationEquipement.title') }}
            <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="invalidState" />
        </template>
        <b-card>
            <b-form>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('actions.consignationEquipement.targetVariable') + ' *'" label-for="valueToWrite">
                            <b-card id="valueToWrite" style="max-height: 500px; overflow-y: auto">
                                <TreeView id="variables" :item="itemListVariables" :multiSelect="false" :checked="true" :onItemChecked="onItemCheck" />
                            </b-card>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('actions.consignationEquipement.valueToWrite') + ' *'" label-for="valueToWrite">
                            <b-card id="valueToWrite">
                                <b-row>
                                    <b-form-group :label="$t('Type') + ' *'" label-for="valueType">
                                        <b-form-radio-group id="valueType" v-model="ValueType" :options="availableValueTypes" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }"> </b-form-radio-group>
                                    </b-form-group>
                                </b-row>
                                <b-row v-if="ValueType == 'constant'">
                                    <b-col>
                                        <b-form-group :label="$t('Value') + ' *'" label-for="value">
                                            <b-form-input id="value" v-model="Value" :state="valueState" type="number" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row v-if="ValueType == 'variable'">
                                    <b-col>
                                        <b-form-group :label="$t('Group') + ' *'" label-for="group">
                                            <b-form-select id="group" v-model="Group" :options="availableGroups" v-on:change="groupChanged" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row v-if="ValueType == 'variable'">
                                    <b-col>
                                        <b-form-group :label="$t('Item') + ' *'" label-for="item">
                                            <b-form-select id="item" v-model="Item" :state="itemState" :options="availableItems" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row v-if="ValueType == 'variable'">
                                    <b-col>
                                        <b-form-group :label="$t('Type') + ' *'" label-for="itemType">
                                            <b-form-select id="itemType" v-model="ItemType" :options="availableItemTypes" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>
    </b-tab>
</template>

<script>
import ToastAlert from '@/utils/ToastAlert';
import TreeView from '@/components/TreeView.vue';
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
export default {
    name: 'ConsignationEquipement',
    props: {
        /**
         * Action object from parent component. Contains the equipment concerned,
         * and details about the action if opening an existing action.
         */
        action: Object,
        /**
         * Current project's Guid
         */
        propProject: String,
    },
    components: {
        TreeView,
    },
    data() {
        return {
            SelectedVariables: [],
            ValueType: 'constant',
            availableValueTypes: [
                { text: this.$t('Constant'), value: 'constant' },
                { text: this.$t('Variable'), value: 'variable' },
            ],
            Value: 0,
            Group: '',
            Item: '',
            ItemType: 'Valeur',
            availableGroups: [],
            availableItems: [],
            availableItemTypes: ['Valeur', 'ValeurN1', 'Min', 'Max'],
            itemListVariables: [],
        };
    },
    computed: {
        invalidState() {
            return this.valueState == false || this.itemState == false;
        },
        valueState() {
            if (this.ValueType == 'constant' && (this.Value === null || this.Value === '')) return false;
            return null;
        },
        itemState() {
            if (this.ValueType == 'variable' && (this.Item === null || this.Item === '')) return false;
            return null;
        },
    },
    async mounted() {
        if (!this.$store.state.auth.user.permissions.PermissionRead) return;
        var data = await ProdComEquipmentService.getGroups(this.action.Equipment, this.propProject);
        if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
        else {
            this.availableGroups = data.ret.List;
        }
        this.Group = this.availableGroups[0];

        await this.groupChanged();

        data = await ProdComEquipmentService.getEquipmentVariables(this.action.Equipment, this.propProject);

        if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
        else {
            for (const variable of data.ret.List) {
                var existingGroup = this.itemListVariables.find((ac) => ac.text == variable.Group);
                if (existingGroup == null) {
                    this.itemListVariables.push({ id: variable.Group, text: variable.Group, expanded: false, items: [], contextMenuSelected: false, selected: false, checkStatus: false });
                    existingGroup = this.itemListVariables.find((ac) => ac.text == variable.Group);
                }
                existingGroup.items.push({ id: variable.Group + '_' + variable.Name, text: variable.Name, expanded: false, items: null, icon: 'fas fa-tag', iconColor: '#f57c00', obj: variable, contextMenuSelected: false, selected: false, items: null, checkStatus: false });
            }
        }

        if (this.action.ActionConfiguration != null) {
            console.log(this.action.ActionConfiguration);
            this.ValueType = this.action.ActionConfiguration.Constant ? 'constant' : 'variable';
            this.Value = this.action.ActionConfiguration.Value;
            this.Group = this.action.ActionConfiguration.Group;
            if (!this.action.ActionConfiguration.Constant && this.Group != null) await this.groupChanged();
            this.Item = this.action.ActionConfiguration.Item;
            this.ItemType = this.action.ActionConfiguration.ItemType;
            for (var variable of this.action.ActionConfiguration.SelectedVariables) {
                var group = this.itemListVariables.find((ac) => ac.text == variable.Group);
                if (group != null) {
                    var item = group.items.find((it) => it.text == variable.Item);
                    if (item != null) {
                        item.checkStatus = true;
                        this.SelectedVariables.push(variable);
                    }
                }
                // Todo: check those variables
            }
        }
        // Add checked item count / item count to group name
        for (var group of this.itemListVariables) {
            var checkedCount = group.items.filter((it) => it.checkStatus).length;
            group.text = group.id + ' (' + checkedCount + '/' + group.items.length + ')';
        }
    },
    methods: {
        async validate() {
            if (this.invalidState) return null;
            var result = {
                Constant: this.ValueType == 'constant',
                Value: this.Value,
                Group: this.Group,
                Item: this.Item,
                ItemType: this.ItemType,
                SelectedVariables: this.SelectedVariables,
            };
            return result;
        },
        async groupChanged(e) {
            if (!this.$store.state.auth.user.permissions.PermissionRead) return;
            const data = await ProdComEquipmentService.getItems(this.action.Equipment, this.Group, this.propProject);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
            else {
                this.availableItems = data.ret.List.map((it) => it.Name);
                this.Item = this.availableItems[0];
            }
        },
        async onItemCheck(e) {
            if (e.checkStatus) {
                // Item added
                this.SelectedVariables.push({ Group: e.parent.id, Item: e.text });
            } else {
                // Item removed
                const itemToRemoveIndex = this.SelectedVariables.findIndex((it) => it.Group == e.parent.id && it.Item == e.text);
                if (itemToRemoveIndex != -1) this.SelectedVariables.splice(itemToRemoveIndex, 1);
            }
            // Update group text
            var group = this.itemListVariables.find((ac) => ac.id == e.parent.id);
            if (group != null) {
                var checkedCount = group.items.filter((it) => it.checkStatus).length;
                group.text = group.id + ' (' + checkedCount + '/' + group.items.length + ')';
            }
        },
    },
};
</script>