import utils from "@/utils";

class ProdComActionService {

    async getActions(equipmentName, projectId){
        return await utils.PostQuery('actions.GetActions', 
        {
            EquipmentName: equipmentName,
            Id: projectId
        });
    }

    async getActionDetails(actionName, groupName, equipmentName, projectId){
        return await utils.PostQuery('actions.GetActionDetails', 
        {
            EquipmentName: equipmentName,
            ActionName: actionName,
            ActionGroupName: groupName,
            Id: projectId
        });
    }

    async getTriggerTypes(){
        return await utils.PostQuery('actions.GetTriggerTypes');
    }

    async getActionTypes(){
        return await utils.PostQuery('actions.GetAvailableActionTypes');
    }

    async createAction(projectId, equipmentName, actionType, actionData){
        return await utils.PostQuery('actions.CreateAction', 
        {
            ProjectId: projectId,
            EquipmentName: equipmentName,
            ActionType: actionType,
            TriggerData: actionData
        });
    }

    async deleteAction(project, equipmentName, groupName, actionName){
        return await utils.PostQuery('actions.DeleteAction', 
        {
            Id: project,
            EquipmentName: equipmentName,
            ActionName: actionName,
            ActionGroupName: groupName
        });
    }

    async deleteGroupAction(project, equipmentName, groupName){
        return await utils.PostQuery('actions.DeleteGroupAction', 
        {
            Id: project,
            EquipmentName: equipmentName,
            ActionGroupName: groupName,
        });
    }

    async deleteAllAction(project, equipmentName){
        return await utils.PostQuery('actions.DeleteAllAction', 
        {
            Id: project,
            EquipmentName: equipmentName,
        });
    }

    async updateAction(actionType, actionData, project, equipmentName, groupName, actionName){
        return await utils.PostQuery('actions.UpdateAction', 
        {
            ProjectId: project,
            EquipmentName: equipmentName,
            ActionType: actionType,
            TriggerData: actionData,
            ActionName: actionName,
            ActionGroupName: groupName
        });
    }

    async getActionDiagram(projectId, equipmentName){
        return await utils.PostQuery('actions.GetActionDiagram', 
        {
            Id: projectId,
            EquipmentName: equipmentName,
        });
    }

    async duplicateAction( OldAct,NewAct, eqName, ProjectId) {
        return await utils.PostQuery('actions.duplicateAction', 
        {
            NewActionName: NewAct,
            OldActionName: OldAct,
            EquipmentName: eqName,
            Id: ProjectId
        })
    }
}

export default new ProdComActionService();