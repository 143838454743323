<template>
    <b-tabs pills card h-100 d-flex flex-column style="width: 100%">
        <!-- Connection configuration -->
        <b-tab>
            <template #title>
                <span class="tabHeaderText">
                    {{ $t('Connection') }}
                    <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="invalidState" />
                </span>
            </template>
            <div>
                <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('opcVersion') + ' *'" label-for="opcVersion">
                            <b-form-select id="opcVersion" v-model="opcVersion" :options="opcVersions" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" v-if="opcVersion == 'V1' && shown">
                        <b-form-group :label="$t('Connexion Parameters') + ' *'" label-for="v1ConnexionParameters">
                            <b-form-select id="v1ConnexionParameters" :state="chosenConnexionState" v-model="chosenConnexion" :disabled="availableConnexions.length == 0" :options="availableConnexions" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" v-if="opcVersion == 'V123'">
                        <b-form-group :label="$t('opcConnexionType') + ' *'" label-for="connexionType">
                            <b-form-radio-group id="connexionType" v-model="connexionType" :options="connexionTypeOptions" name="connexionType" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" v-if="opcVersion == 'RemoteXml'">
                        <b-form-group :label="$t('opcXmlUrl') + ' *'" label-for="xmlUrl">
                            <b-form-input id="xmlUrl" :state="urlState" v-model="url" name="xmlUrl" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <hr color="grey" v-if="opcVersion == 'V123' && connexionType == 'remote'" />
                <b-row v-if="opcVersion == 'V123' && connexionType == 'remote'">
                    <b-col cols="6">
                        <b-form-group :label="$t('opcXmlUrl') + ' *'" label-for="url">
                            <b-form-input id="url" :state="urlState" v-model="url" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group :label="$t('Domain')" label-for="opcdomain">
                            <b-form-input id="opcdomain" v-model="domain" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group :label="$t('User name')" label-for="opcusername">
                            <b-form-input id="opcusername" v-model="login" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group :label="$t('Password')" label-for="opcpassword">
                            <b-form-input id="opcpassword" type="password" v-model="password" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <hr color="grey" v-if="opcVersion == 'V123'" />
                <b-row v-if="opcVersion == 'V123'">
                    <b-col cols="6">
                        <b-form-group :label="$t('Specification')" label-for="opcV2Spec">
                            <b-form-select v-model="chosenSpecification" :options="specifications" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" v-if="shown">
                        <b-form-group :label="$t('Connexion Parameters') + ' *'" label-for="v1ConnexionParameters">
                            <b-form-select id="v1ConnexionParameters" :state="chosenConnexionState" v-model="chosenConnexion" :options="availableConnexions" :disabled="availableConnexions.length == 0" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('numberOfAttemptsToReconnect') + ' *'" label-for="Max_Reconnection">
                            <b-form-select :options="Max_Reconnection_Select" v-model="Max_Reconnection"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group :label="$t('reconnectInterval') + ' *'" label-for="reconnectInterval">
                            <b-input-group :append="$t('Seconds')" style="color: var(--text-color)">
                                <b-form-input
                                    @change="
                                        (value) => {
                                            if (value == null || value == '') {
                                                this.interval_reconnect = 10;
                                            } else if (value > 3600) {
                                                this.interval_reconnect = 3600;
                                            } else if (value < 5) {
                                                this.interval_reconnect = 5;
                                            }
                                        }
                                    "
                                    v-model="interval_reconnect"
                                    id="reconnectInterval"
                                    min="5"
                                    max="3600"
                                    :type="'number'"
                                ></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
        </b-tab>
        <!-- Item configuration -->
        <b-tab>
            <template #title>
                {{ $t('Variables') }}
                <b-badge pill variant="info">{{
                    groups
                        .map((gr) => gr.Items.length)
                        .reduce(function (a, b) {
                            return a + b;
                        }, 0)
                }}</b-badge>
            </template>
            <ItemConfiguration
                ref="itemConfiguration"
                :columns="itemColumns"
                :groups="groups"
                :OnItemBrowse="onItemBrowse"
                :createChildren="createChildren"
                :separateGroupConfig="true"
                :groupConfiguration="groupConfiguration"
                :readonly="!this.$store.state.auth.user.permissions.PermissionWrite"
                :updateMode="updateMode"
                :selectedUrl="this.chosenConnexion"
                :RefreshVarCounter="refreshVariablesCounter"
            />
        </b-tab>
    </b-tabs>
</template>

<script>
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ToastAlert from '@/utils/ToastAlert';
import TreeView from '@/components/TreeView.vue';
import ItemConfiguration from '@/components/configuration/Equipments/ItemConfiguration.vue';
import { uuid } from 'vue-uuid';

export default {
    name: 'ConfigOPCDA',
    props: {
        equipment: Object,
        onValidate: Function,
        existingConfig: Boolean,
        updateMode: Boolean,
    },
    components: {
        TreeView,
        ItemConfiguration,
    },
    async mounted() {
        if (this.existingConfig) {
            this.loading = true;
            this.login = this.equipment.Login;
            this.password = this.equipment.Password;
            this.domain = this.equipment.Domain;
            this.opcVersion = this.equipment.OpcVersion;
            //this.Url = this.equipment.Url?.HostName ?? this.equipment.RemoteAddress ?? '';
            this.url = this.equipment.Url?.HostName ?? this.equipment.RemoteAddress ?? '';
            this.chosenSpecification = this.equipment.Specification;
            if (this.equipment.OpcVersion == 'V1') {
                this.availableConnexions = [];
                this.chosenConnexion = {};
                await this.onConnectionParameterInput();
                this.chosenConnexion = this.equipment.ConnectionName;
            } else if (this.equipment.OpcVersion == 'V123') {
                this.availableConnexions = [];
                await this.onConnectionParameterInput();
            }
            this.connexionType = this.equipment.IsRemote ? 'remote' : 'local';
            this.interval_reconnect = this.equipment.interval_reconnect;
            this.Max_Reconnection = this.equipment.Max_Reconnection;
            this.loading = false;
        } else {
            this.availableConnexions = [];
            this.chosenConnexion = {};
            await this.onConnectionParameterInput();
            this.itemColumns[2].options = (await ProdComEquipmentService.opcDaGetAvailableTypes())?.ret ?? null;
        }
    },
    watch: {
        async opcVersion(newValue, oldValue) {
            if (this.loading) return;
            this.availableConnexions = [];
            this.chosenConnexion = {};
            await this.onConnectionParameterInput();
        },
        async connexionType(newValue, oldValue) {
            if (this.loading) return;
            this.availableConnexions = [];
            this.chosenConnexion = {};
            await this.onConnectionParameterInput();
        },
        async chosenSpecification(newValue, oldValue) {
            if (this.loading) return;
            this.availableConnexions = [];
            this.chosenConnexion = {};
            await this.onConnectionParameterInput();
        },
    },
    computed: {
        invalidState() {
            return this.urlState === false || this.chosenConnexionState === false;
        },
        chosenConnexionState() {
            return this.chosenConnexion == null || this.chosenConnexion == '' || this.chosenConnexion == {} ? false : null;
        },
        urlState() {
            return (this.opcVersion == 'RemoteXml' && (this.url == null || this.url == '')) || (this.opcVersion == 'V123' && this.connexionType == 'remote' && (this.url == null || this.url == '')) ? false : null;
        },
    },
    data() {
        return {
            opcVersions: [
                { value: 'V1', text: this.$t('equipment.opcda.DA12Local') },
                { value: 'V123', text: this.$t('equipment.opcda.DA123LocalRemote') },
                { value: 'RemoteXml', text: this.$t('equipment.opcda.XMLDA1') },
            ],
            opcVersion: 'V1',
            connexionTypeOptions: [
                { value: 'local', text: this.$t('local') },
                { value: 'remote', text: this.$t('remote') },
            ],
            connexionType: 'local',
            url: '127.0.0.1',
            availableConnexions: [],
            chosenConnexion: {},
            specifications: [
                { value: 'DA10', text: 'Data Access 1.0a' },
                { value: 'DA20', text: 'Data Access 2.XX' },
                { value: 'DA30', text: 'Data Access 3.00' },
            ],
            chosenSpecification: 'DA10',
            shown: true,
            login: '',
            password: '',
            domain: '',
            groups: this.equipment?.Groups ?? [{ id: uuid.v4(), Name: `${this.$t('Group')} 1`, Active: true, RefreshRate: 1000, DeadBand: 0, MinArchi: 0, Items: [] }],
            needsReinitItemClientList: false,
            itemColumns: [
                { key: 'Name', dataField: 'Name', dataType: 'string', caption: this.$t('Name'), required: true },
                { key: 'Address', dataField: 'Address', dataType: 'string', caption: this.$t('Address'), required: true },
                { key: 'Type', dataField: 'Type', dataType: 'string', caption: this.$t('Type'), required: true, width: 120 },
                { key: 'Active', dataField: 'Active', dataType: 'boolean', caption: this.$t('Active'), required: false, alignment: 'center', width: 100 },
            ],
            groupConfiguration: {
                rows: [
                    {
                        columns: [
                            { text: this.$t('Refresh rate') + ' *', variable: 'RefreshRate', inputType: 'number', cols: '6', min: '100', max: '99999', defaultValue: 1000 },
                            { text: this.$t('Dead band') + ' (%) *', variable: 'DeadBand', inputType: 'number', cols: '6', min: '100', max: '99999', defaultValue: 0 },
                        ],
                    },
                    {
                        columns: [{ text: this.$t('minArchiveTime') + ' *', variable: 'MinArchi', inputType: 'number', cols: '6', min: '100', max: '99999', defaultValue: 0 }],
                    },
                ],
            },
            loading: false,
            Max_Reconnection_Select: [
                { text: '∞', value: 0 },
                { text: '0', value: -1 },
                { text: '1', value: 1 },
                { text: '2', value: 2 },
                { text: '5', value: 5 },
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: '25', value: 25 },
                { text: '50', value: 50 },
                { text: '75', value: 75 },
                { text: '100', value: 100 },
            ],
            interval_reconnect: 10,
            Max_Reconnection: 0,
        };
    },
    methods: {
        validate() {
            return this.invalidState ? null : true;
        },
        async createChildren(parent, treeviewInstance) {
            if (parent == null)
                return [
                    {
                        Name: 'Objects',
                        Address: 0,
                        Type: '',
                        IsFolder: true,
                        Active: true,
                        expanded: true,
                    },
                ];

            try {
                const parentId = parent.key;
                const parentNodeIds = [];
                // Add all parent nodes
                let currentParent = parent;
                while (currentParent != null && currentParent.key != 0) {
                    parentNodeIds.push(currentParent.itemData.Name);
                    currentParent = currentParent.parent;
                }
                // Reverse the list to have the root node first
                parentNodeIds.reverse();
                const data = await ProdComEquipmentService.opcDaBrowseNodeItems(this.opcVersion, this.connexionType == 'remote', this.url, this.login, this.password, this.domain, this.chosenSpecification, this.chosenConnexion, parentNodeIds);
                if (data.success == 'n') {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                    return [];
                } else {
                    if (parent.key == 0) {
                        parent.itemData.ChildsCount = data.ret.length;
                        treeviewInstance.repaint();
                    }
                    let ret = data.ret.map((d) => {
                        d.parentId = parentId;
                        d.expanded = data.ret.length == 1;
                        d.disabled = !d.IsFolder && (d.Type == null || d.Type === '');
                        return d;
                    });
                    return ret;
                }
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        async saveEquipmentCommunication() {
            await this.$refs.itemConfiguration.saveEquipmentCommunication();
        },
        /**
         * Synchronizes the data changes from the wizard into the given equipment. This method should be called before updateing / saving / adding
         * the equipment in the parent control.
         * @public This method is public.
         */
        async synchronizeEquipment() {
            this.equipment.Login = this.login;
            this.equipment.Password = this.password;
            this.equipment.Domain = this.domain;
            if (this.opcVersion == 'V1') {
                this.equipment.ConnectionName = this.chosenConnexion;
            } else if (this.opcVersion == 'V123') {
                this.equipment.ConnectionName = this.chosenConnexion.Name;
                this.equipment.Url = this.chosenConnexion.Url;
            }
            this.equipment.RemoteAddress = this.url;
            this.equipment.OpcVersion = this.opcVersion;
            this.equipment.Groups = this.groups;
            this.equipment.IsRemote = this.connexionType == 'remote';
            this.equipment.Specification = this.chosenSpecification;
            this.equipment.interval_reconnect = this.interval_reconnect;
            this.equipment.Max_Reconnection = this.Max_Reconnection;
        },
        async refreshVariablesCounter(searchingForItems, variableCounter) {
            this.synchronizeEquipment();
            const res = await ProdComEquipmentService.opcDaCheckVariablesCounter({ Name: this.equipment.ConnectionName });
            if (res.success == 'y') {
                variableCounter.content = res.ret;
                variableCounter.is = true;
                while (searchingForItems.is) {
                    let res = await ProdComEquipmentService.opcDaCheckVariablesCounter({ Name: this.equipment.ConnectionName });
                    if (Number.isInteger(res.ret) && res.ret != 0) {
                        variableCounter.content = res.ret;
                    }
                    await new Promise((resolve) => setTimeout(resolve, 100));
                }
            }
        },
        /**
         * Called when browsing for items in configuration. Returns the list of available items
         */
        async onItemBrowse(e) {
            // TODO: search for items in OPC UA conf
            try {
                const data = await ProdComEquipmentService.opcDaBrowseItems(this.opcVersion, this.connexionType == 'remote', this.url, this.login, this.password, this.domain, this.chosenSpecification, this.chosenConnexion);
                if (data.success == 'n') {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                    return [];
                } else {
                    return data.ret;
                }
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        async onConnectionParameterInput(e) {
            if (this.opcVersion == 'RemoteXml') return;
            const data = await ProdComEquipmentService.opcDaGetConnexions(this.opcVersion, this.connexionType == 'remote', this.url, this.login, this.password, this.domain, this.chosenSpecification);
            if (data.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                this.availableConnexions = [];
            } else {
                this.availableConnexions = [];
                // Check if data.ret is an array or an object
                if (!Array.isArray(data.ret)) return;
                for (const conn of data.ret) {
                    if (this.opcVersion == 'V123') {
                        if (conn != null) {
                            this.availableConnexions.push({ value: conn, text: conn.Name });
                        }
                    } else {
                        this.availableConnexions.push(conn);
                    }
                }
                if (this.availableConnexions != null && this.availableConnexions.length > 0) {
                    if (this.opcVersion == 'V123') this.chosenConnexion = this.availableConnexions.find((it) => it?.value?.Name == this.equipment?.ConnectionName)?.value;
                    else if (this.opcVersion == 'V1') this.chosenConnexion = this.availableConnexions.find((it) => it == this.equipment?.ConnectionName);
                    if ((this.chosenConnexion == null || this.chosenConnexion == '') && this.availableConnexions.length > 0) this.chosenConnexion = this.opcVersion == 'V123' ? this.availableConnexions[0].value : this.availableConnexions[0];
                }
            }
            // console.trace();
        },
    },
};
</script>
