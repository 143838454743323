<template>
    <b-container
        fluid
        class="home_monitoring"
        v-bind:style="{
            height: 'calc(100% - 150px)!important',
        }"
        v-if="loggedIn"
    >
    <b-row>
        <b-col style="margin-top: 10px; margin-bottom: 15px">
            <div>
                <b-row style=" height: 49px;">
                    <b-col cols="4" style="float:none;margin:auto;">
                            <font-awesome-icon 
                                class="button cursor-pointer rotate"
                                v-bind:class="{'down': equipmentListVisible}"
                                icon="fa-solid fa-caret-right" 
                                size="lg" 
                                :title="$t('Select equipment')"
                                style="margin-left: 10px; margin-right: 10px;" 
                                role="button"
                                href="#collapseExample"
                                v-b-toggle.collapse-1/>
                    </b-col>
                    <b-col cols="4" style="float:none;margin:auto;">
                        <div style="text-align: center; vertical-align: middle;" v-if="$store.state.selectedEquipmentMonitoring != null">
                            {{ $store.state.selectedEquipmentMonitoring }} ({{selectedEquipmentActionCount}})
                        </div>
                    </b-col>
                    <b-col cols="4" style="float:none;margin:auto;">
                        <div style="text-align: right; vertical-align: middle;">
                            <font-awesome-icon icon="fas fa-square-question" class="top-menu-icon mr-1 fa-lg" v-on:click="displayHelp" :title="$t('Help')"  />
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-col>
    </b-row>
    <b-collapse id="collapse-1" class="mt-2" visible v-model="equipmentListVisible">
    <b-row>
        <b-col>
        <!-- Equipment list grid -->
            <DxDataGrid 
            :dataSource="dsEquipments"
            :allow-column-reordering="true"
            :repaint-changes-only="true"
            :show-borders="true"
            :selection="{ mode: 'single' }"
            :columnAutoWidth="false"
            :allow-column-resizing="true"
            v-on:initialized="onEquipmentListInitialized"
            @selection-changed="onSelectionChangedEquipmentList">
                <template #status-equipment="{data}">
                    <div class="text-center">
                        <!-- ServerState.Stopped -->
                        <font-awesome-icon v-if="data.data.StatusEquipment == 0" icon="stop" class="fa-lg" color="#ffca28" />
                        <!-- ServerState.Started -->
                        <font-awesome-icon v-else-if="data.data.StatusEquipment == 1" icon="play" class="fa-lg" color="#4dc615"/>
                        <!-- ServerState.StartPending -->
                        <font-awesome-layers v-else-if="data.data.StatusEquipment == 2">
                            <font-awesome-icon icon="play" class="fa-lg" color="#4dc615"/>
                            <font-awesome-icon icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0"/>
                        </font-awesome-layers>
                        <!-- ServerState.StopPending -->
                        <font-awesome-layers v-else-if="data.data.StatusEquipment == 3">
                            <font-awesome-icon icon="stop" class="fa-lg" color="#c72614"/>
                            <font-awesome-icon icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0"/>
                        </font-awesome-layers>
                    </div>
                </template>
                <template #status-communication="{data}">
                    <div class="text-center">
                        <!-- ComState.Disconnected -->
                        <font-awesome-icon v-if="data.data.StatusEquipment == 0" icon="times-circle" class="fa-lg" color="#c72614" />
                        <!-- ComState.Connected -->
                        <font-awesome-icon v-else-if="data.data.StatusEquipment == 1" icon="check-circle" class="fa-lg" color="#4dc615"/>
                        <!-- ComState.Reconnecting -->
                        <font-awesome-icon v-else-if="data.data.StatusEquipment == 2" icon="spinner-third" class="fa-spin-custom fa-lg" color="#c8b900"/>
                        <!-- ComState.SubscribeError -->
                        <font-awesome-icon v-else-if="data.data.StatusEquipment == 3" icon="exclamation-circle" class="fa-lg" color="#c66900"/>
                    </div>
                </template>
                <template #status-datasource="{data}">
                    <div class="text-center">
                        <!-- DBState.NotConfigured -->
                        <font-awesome-icon v-if="data.data.StatusDataSource == 0" icon="empty-set" class="fa-lg"/>
                        <!-- DBState.Disconnected -->
                        <font-awesome-icon v-else-if="data.data.StatusDataSource == 1" icon="times-circle" class="fa-lg" color="#c72614"/>
                        <!-- DBState.Connected -->
                        <font-awesome-icon v-else-if="data.data.StatusDataSource == 2" icon="check-circle" class="fa-lg" color="#4dc615"/>
                        <!-- DBState.BackupConnected -->
                        <font-awesome-icon v-else-if="data.data.StatusDataSource == 3" icon="exclamation-circle" class="fa-lg" color="#c66900"/>
                    </div>
                </template>
                <template #active="{data}">
                    <div class="text-center">
                        <b-form-checkbox v-model="data.data.Active" disabled></b-form-checkbox>
                    </div>
                </template>
                <template #data-left="{data}">
                    <div class="text-left">
                        {{ data.displayValue + ' (' + data.data.ItemCount + ')' }}
                    </div>
                </template>

                <DxColumnChooser :enabled="true" mode="select" />
                <DxColumn data-field="Name" :visible="true" :caption="$t('Name')" alignment="center" cell-template="data-left"/>
                <DxColumn data-field="Status Equipment" :visible="true" cell-template="status-equipment" :caption="$t('Equipment')" alignment="center"/>
                <DxColumn data-field="Status Communication" :visible="true" cell-template="status-communication" :caption="$t('Communication')" alignment="center"/>
                <DxColumn data-field="Status Data Source" :visible="true" cell-template="status-datasource" :caption="$t('Data Source')" alignment="center"/>
                <DxColumn data-field="Active" :visible="true" cell-template="active" :caption="$t('Active')" alignment="center"/>
                <DxColumn data-field="Type" :group-index="0" data-type="string"/>
            </DxDataGrid>
        </b-col>
    </b-row>
    </b-collapse>
    <!-- Only display data source if connection has started -->
    <b-row style="margin-top: 7px;" v-if="connectionStarted">
        <b-col>
            <DxDataGrid
                :dataSource="dataSource"
                :allow-column-reordering="true"
                :repaint-changes-only="true"
                :show-borders="true"
                :highlight-changes="true"
                :selection="{ mode: 'single' }"
                :columnAutoWidth="true"
                :allow-column-resizing="true"
                @row-prepared="onRowPrepared">
                    <DxScrolling mode="Standard"/>
                    <DxPaging
                        :enabled="true"
                        :page-size="20"
                        :visible="true"
                    />
                    <DxPager
                        :show-page-size-selector="true"
                        :show-info="true"
                        :visible="true"
                    />
                    <DxColumnChooser :enabled="true" mode="select" />
                    <template #trend-template="{ data }">
                        <div class="text-center">
                            <font-awesome-icon v-if="data.data.Tendance > 0" icon="arrow-alt-up" class="fa-lg rotate-45" />
                            <font-awesome-icon v-else-if="data.data.Tendance < 0" icon="arrow-alt-down" class="fa-lg rotate--45"/>
                            <font-awesome-icon v-else icon="arrow-alt-right" class="fa-lg" />
                        </div>
                    </template>
                    <template #data-status="{data}">
                        <div class="text-center">
                            <div v-if="data.data.Active">
                                <font-awesome-icon v-if="data.displayValue == '0' || data.displayValue == '1'" icon="fa-play" class="fa-lg" color="#c66900" />
                                <font-awesome-layers v-if="data.displayValue == '2'">
                                    <font-awesome-icon icon="fa-play" class="fa-lg" color="#4dc615" />
                                    <font-awesome-icon icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0"/>
                                </font-awesome-layers>
                                <font-awesome-icon v-else-if="data.displayValue == '3'" icon="fa-circle-exclamation" class="fa-lg" color="#e64a19" />
                            </div>
                            <div v-else>
                                <font-awesome-icon icon="fa-pause" class="fa-lg" color="#c66900" />
                            </div>
                        </div>
                    </template>
                    <template #data-hd="{data}">
                        <div class="text-center">
                            {{ format_date(data.displayValue, 'DD/MM/YYYY HH:mm:ss') }}
                        </div>
                    </template>
                    <template #data-lastresult="{data}">
                        <div class="text-center">
                            <div v-if="data.data.LastResult">
                                <span>OK</span>
                            </div>
                            <div v-else>
                                <span>NOK</span>
                            </div>
                        </div>
                    </template>
                    <template #data-durations="{data}">
                        <div class="text-center" v-if="data.displayValue != null">
                            <DxChart
                                :data-source="ArrayToObject(data.displayValue)">
                                <DxSize :height="50" :width="400"/>
                                <DxSeries
                                    type="line"
                                    name='durations'
                                    argument-field="key"
                                    value-field="value"
                                    :showInLegend="false">
                                    <DxPoint :visible="false" />
                                </DxSeries>
                                <DxAnimation :enabled="false"/>
                                <!-- <DxValueAxis :visualRange="[0, Math.max(200, Math.max(...data.displayValue))]"/> -->
                            </DxChart>
                        </div>
                    </template>
                    <template #data-group="{data}">
                        <p v-if="data?.value == null || data?.value == undefined"></p>
                        <p v-else-if="data?.value == 'Default'">{{$t('Default')}} ({{ data.data.items?.length }})</p>
                        <p v-else>{{ data?.value?.replace("_", " ") }} ({{ data.data.items?.length }})</p>
                    </template>
                    <DxColumn :group-index="1" data-field="Group" :caption="$t('Group')" data-type="string" alignment="center" group-cell-template="data-group"/>
                    <DxColumn data-field="Name" :caption="$t('Name')" data-type="string"/>
                    <DxColumn data-field="Status" :caption="$t('Status')" data-type="string" alignment="center" cell-template="data-status"/>
                    <DxColumn data-field="LastStartDate" :caption="$t('LastStartDate')" data-type="string" alignment="center" cell-template="data-hd"/>
                    <DxColumn data-field="LastResult" :caption="$t('LastResult')" data-type="string" alignment="center" cell-template="data-lastresult"/>
                    <DxColumn data-field="LastDuration" :caption="$t('LastDuration') + ' (ms)'" data-type="string" alignment="center"/>
                    <DxColumn data-field="Durations" :caption="$t('Duration1') + ' (ms)'" data-type="string" alignment="center" cell-template="data-durations"/>
                    <DxColumn data-field="Active" :visible="false" :caption="$t('Active')" data-type="bool" alignment="center"/>
            </DxDataGrid>
        </b-col>
    </b-row>
    </b-container>
</template>

<script>
import Action from "@/components/configuration/Actions/Action.vue";
import DataSource from "@/components/configuration/DataSources/DataSource.vue";
import ProdComMonitoringService from "@/services/prodcom.monitoring.service.js";
import {HttpTransportType, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import ToastAlert from '@/utils/ToastAlert';
import constants from "@/utils/constants";
import dayjs from "dayjs";
import ChartCell from "@/components/ChartCell.vue"
import DxChart, 
{ DxSeries, DxPoint, DxAnimation, DxValueAxis, DxSize} 
from 'devextreme-vue/chart';

import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxSearchPanel,
  DxPaging,
  DxPager,
  DxToolbar,
  DxItem,
  DxScrolling,
  DxColumnChooser,
} from 'devextreme-vue/data-grid';
import CustomStore from 'devextreme/data/custom_store';

export default {
    name: 'ActionMonitor',
    components: {
        Action,
        DataSource,
        DxColumn,
        DxGroupPanel,
        DxGrouping,
        DxPaging,
        DxScrolling,
        DxPager,
        DxSearchPanel,
        DxDataGrid,
        DxToolbar,
        DxItem,
        DxColumnChooser,
        DxSeries,
        DxPoint,
        DxChart,
        DxAnimation,
        DxValueAxis,
        DxSize,
        ChartCell
    },
    data() {
        return {
            dsEquipments: null,
            dataGridEquipmentList: null,
            isIframe: window.location !== window.parent.location,
            timerRefreshEquipments: null,
            equipmentListVisible: true,
            hubConnection: null,
            dataSource: null,
            connectionStarted: false,
            selectedEquipmentActionCount: 0,
        };
    },
    async mounted(){
        // Don't do anything if not logged in
        if(!this.loggedIn) return;
        // Get the list of equipments at startup of component, and automatically select the first one.
        await this.fetchEquipmentList();
        if(this.$store.state.selectedEquipmentMonitoring == null && this.dsEquipments.length > 0){
            this.$store.commit('selectEquipmentMonitor', this.dsEquipments[0].Name);
        }
        if(this.$store.state.selectedEquipmentMonitoring != null){
            var selectedRow = this.dsEquipments.find((val) => val.Name == this.$store.state.selectedEquipmentMonitoring);
            this.dataGridEquipmentList.selectRows(selectedRow);
        }
        this.equipmentListVisible = false

    },
    created(){
        // Don't do anything if not logged in
        if(!this.loggedIn) return;
        // Set the different background tasks:
        // - Getting the list and status of every equipment
        // - If an equipment is selected, get its list of variables
        // - Report other ProdCom monitoring data treme 
        this.timerRefreshEquipments = setInterval(async () => {
            await this.fetchEquipmentList();
        }, 1000);
    },
    beforeDestroy(){
        // Make sure we correctly stop the different background tasks here so that
        // no API calls are done where none should be made.
        clearInterval(this.timerRefreshEquipments);
        this.dsEquipments = null;
        this.stopMonitoring();
    },
    methods: {
        /**
         * Formats the date to the given format for correct display in the data grids.
         */
        format_date(value, format){
            if (value) {
            return dayjs(value).format(format)
            }
        },
        
        /**
         * Stores the reference to the Equipment data grid
         */
        async onEquipmentListInitialized(e){
            this.dataGridEquipmentList = e.component;
        },

        onRowPrepared(e){
            if(e.rowType == "data"){
                if(e.data.Status == 3){ // Error
                    e.rowElement.style.color = "#e64a19";
                }
            }
        },
        
        /**
         * Event fired when changing the selected equipment
         */
        async onSelectionChangedEquipmentList({selectedRowsData}){
            this.$store.commit('selectEquipmentMonitor', selectedRowsData[0].Name);
            // When changing equipment, unselect the previous one and cut the SignalR connection to recreate it
            // with the new equipment (the callback will be named based on the equipment name)
            this.stopMonitoring();

            // Recreate the SignalR connection hub
            this.hubConnection = new HubConnectionBuilder()
                .withUrl(this.$store.state.settings.API_URL + `/monitoring?equipmentName=${selectedRowsData[0].Name}&clientType=${constants.MONITOR_CLIENT_TYPE_WEB_ACTION_MONITORING}`, {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets
                })
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Debug)
                .build()
            // Recreate the datasource and load initial data
            const store = new CustomStore({
                load: async () => {
                    var response = await ProdComMonitoringService.getActions(this.$store.state.selectedEquipmentMonitoring);
                    this.selectedEquipmentActionCount = response.ret.length;
                    return response.ret;
                },
                key: 'Key',
            })

            // Start connection
            await this.hubConnection.start()

            // Real time update: update the data source with push / update method.
            this.hubConnection.on(constants.MONITOR_VALUEUPDATE, (data) => {}); // Empty callback to avoid warning messages on browser console
            this.hubConnection.on(constants.MONITOR_ACTIONUPDATE, 
                (data) => {
                    if(data.Equipment == this.$store.state.selectedEquipmentMonitoring && data.data !== null && data.data !== undefined){
                        store.push([{type: 'update', key: data.data.Key, data: data.data}]);
                    }
                });
            // Reassign datasource
            this.dataSource = store;
            this.connectionStarted = true;
        },

        /**
         * Closes the SignalR connection and cleans up the object.
         */
        stopMonitoring(){
            if(this.hubConnection != null){
                this.hubConnection.stop();
                this.hubConnection = null;
            }
            this.connectionStarted = false;
        },
        /**
         * Periodically fetch the equipment status from REST API (move to SignalR... Later...)
         */
        async fetchEquipmentList(){
            if(!this.equipmentListVisible) return;
            const data = await ProdComMonitoringService.getEquipments();
            if(data.success == "y"){
                if(this.dsEquipments == null) this.dsEquipments = data.ret;
                else{
                    // Update the value of every item in the list from the received data.
                    data.ret.forEach((e) => {
                        this.dsEquipments.find(eq => {
                            if(eq.Name == e.Name){
                                eq.StatusEquipment = e.StatusEquipment;
                                eq.StatusCommunication = e.StatusCommunication;
                                eq.StatusDataSource = e.StatusDataSource;
                                eq.Active = e.Active;
                                eq.Type = e.Type;
                            }
                        })
                    })
                }
            }
        },
        ArrayToObject(array){
            var result = [];
            for (const el in array) {
                result.push({key: el, value: array[el]});
            }
            return result;
        },
        async displayHelp(){
            this.$store.commit("displayHelp", this.$t('help.run.actions'))
            this.$bvModal.show("bv-modal-help");
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn && this.$store.state.auth.user != null && this.$store.state.auth.user.email != '';
        },
    },
};
</script>

<style lang="scss">
@keyframes spinner_anim {
    to {transform: rotate(360deg);}
}
.fa-spin-custom {
animation: spinner_anim 2s linear infinite;
}
.tabHeaderIcon{
    display: inline-block;
}
.tabHeaderText{
    width: 5px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}
.tabGlobal{
    padding: 0;
    height: calc(100% - 28px);
    background-color: --main-background-color;
}
.tabContent{
    min-height: 100%;
    height: 100%
}
.dx-datagrid-rowsview .order {  
    text-align: center!important;  
}
.rotate {
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
.rotate.down {
    -moz-transform:rotate(90deg);
    -webkit-transform:rotate(90deg);
    transform:rotate(90deg);
}

</style>