<template>
    <b-container
        fluid
        class="home_monitoring"
        v-bind:style="{
            height: 'calc(100% - 150px)!important',
        }"
        v-if="loggedIn"
    >
        <!-- Event list -->
        <b-row>
            <b-col>
                <DxDataGrid 
                    :dataSource="dsLogs"
                    :allow-column-reordering="true"
                    :repaint-changes-only="true"
                    :show-borders="true"
                    :selection="{ mode: 'single' }"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                    @row-dbl-click="onRowDoubleClick">
                    <DxPaging :page-size="40"/>
                    <DxPager
                        :allowed-page-sizes="[10, 20, 40]"
                        :show-page-size-selector="true"
                        :show-info="true"
                        :visible="true"
                    />
                    <DxFilterRow :visible="true"/>
                    <DxHeaderFilter :visible="true"/>
                    <template #data-message="{data}">
                        <div>{{data.displayValue.replace("Service Event: ", "")}}</div>
                    </template>
                    <DxColumn data-field="@Date" :caption="$t('HD')" :visible="true" data-type="datetime" :allow-editing="false" :format="dateFormat"/>
                    <!-- <DxColumn data-field="@Niveau" :caption="$t('eventDetails.level')" :visible="true" data-type="string" :allow-editing="false"/> -->
                    <DxColumn data-field="@Message" :caption="$t('eventDetails.message')" :visible="true" data-type="string" :allow-editing="false" cell-template="data-message"/>
                    <!-- <DxColumn data-field="@Exception" :caption="$t('eventDetails.exception')" :visible="true" data-type="string" :allow-editing="false"/> -->
                </DxDataGrid>
            </b-col>
        </b-row>
        <!-- Event details popup -->
        <b-modal id="eventDetails" v-b-modal.modal-multi-2 size="xl"
        :title="$t('eventDetails.title')" :cancel-title="$t('Cancel')" :ok-title="$t('Ok')">
            <b-form v-if="selectedEvent != null">
                <b-row>
                    <b-col cols="2">
                        {{$t('eventDetails.origin')}} :
                    </b-col>
                    <b-col>
                        <pre style="color: var(--text-color);">{{selectedEvent["@Origine"]}}</pre>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="2">
                        Date :
                    </b-col>
                    <b-col>
                        <pre style="color: var(--text-color);">{{selectedEvent["@Date"]}}</pre>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="2">
                        {{$t('eventDetails.level')}} :
                    </b-col>
                    <b-col>
                        <pre style="color: var(--text-color);">{{selectedEvent["@Niveau"]}}</pre>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="2">
                        {{$t('eventDetails.location')}} :
                    </b-col>
                    <b-col>
                        <pre style="color: var(--text-color);">{{selectedEvent["@Localisation"]}}</pre>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="2">
                        {{$t('eventDetails.message')}} :
                    </b-col>
                    <b-col>
                        <pre style="color: var(--text-color); white-space: pre-wrap">{{selectedEvent["@Message"]}}</pre>
                    </b-col>
                </b-row>
                <b-row v-if="selectedEvent['@Exception'] != ''">
                    <b-col cols="2">
                        {{$t('eventDetails.exception')}} :
                    </b-col>
                    <b-col>
                        <pre style="color: var(--text-color);">{{selectedEvent["@Exception"]}}</pre>
                    </b-col>
                </b-row>
            </b-form>
        </b-modal>
    </b-container>
</template>

<script>
import {HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {
    DxDataGrid,
    DxColumn,
    DxGrouping,
    DxGroupPanel,
    DxSearchPanel,
    DxPaging,
    DxPager,
    DxToolbar,
    DxItem,
    DxScrolling,
    DxColumnChooser,
    DxFilterRow,
    DxHeaderFilter
} from 'devextreme-vue/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import ProdComMonitoringService from "@/services/prodcom.monitoring.service.js";
export default {
    name: 'EventLogger',
    components: {
        DxColumn,
        DxGroupPanel,
        DxGrouping,
        DxPaging,
        DxScrolling,
        DxPager,
        DxSearchPanel,
        DxDataGrid,
        DxToolbar,
        DxItem,
        DxColumnChooser,
        DxFilterRow,
        DxHeaderFilter,
    },
    data() {
        return {
            isIframe: window.location !== window.parent.location,
            dsLogs: [],
            loggingRealTime: null,
            selectedEvent: null,
            dateFormat: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', fractionalSecondDigits: 3 },
        };
    },
    async mounted(){
        // Don't do anything if not logged in
        if(!this.loggedIn) return;
        // Get initial log list and setup websocket for further updates
        this.createLogging();
    },
    beforeDestroy(){
        // Make sure we correctly stop the different background tasks here so that
        // no API calls are done where none should be made.
        this.stopLogging();
    },
    methods: {
        /**
         * Formats the date to the given format for correct display in the data grids.
         */
        format_date(value, format){
            if (value) {
            return dayjs(value).format(format)
            }
        },
        /**
         * Create the refresh timer for the custom store
         */
        createLogging(){
            this.dsLogs = new CustomStore({
                load: async () => {
                    const result = await ProdComMonitoringService.getEventLogs(1000);
                    if(result.success="y")
                    {
                        this.firstLoading = false;
                        return result.ret.map(e => e.logevent);
                    }
                    return [];
                }
            })
            clearInterval(this.loggingRealTime);
            this.loggingRealTime = setInterval(async () => {
                this.$refs.logsGrid?.instance.getDataSource().reload()
            }, 1000);
        },
        /**
         * Correctly the refresh timer
         */
        stopLogging(){
            clearInterval(this.loggingRealTime);
        },
        /**
         * SignalR receive callback. Sets the DxDataGrid data source with
         * the data received.
         */
        async receiveData(value){
            this.dsLogs = JSON.parse(value).map(e => e.logevent); // Forced to parse the JSON output....
        },
        /**
         * When double clicking an event record, open its details in a modal.
         */
        async onRowDoubleClick(e){
            this.selectedEvent = e.data;
            this.$bvModal.show("eventDetails");
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn && this.$store.state.auth.user != null && this.$store.state.auth.user.email != '';
        },
    },
};
</script>

<style lang="scss">
@keyframes spinner_anim {
    to {transform: rotate(360deg);}
}
.fa-spin-custom {
animation: spinner_anim 2s linear infinite;
}
.tabHeaderIcon{
    display: inline-block;
}
.tabHeaderText{
    width: 5px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}
.tabGlobal{
    padding: 0;
    height: calc(100% - 28px);
    background-color: --main-background-color;
}
.tabContent{
    min-height: 100%;
    height: 100%
}
.dx-datagrid-rowsview .order {  
    text-align: center!important;  
}  
</style>